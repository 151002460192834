// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
import './_slider'; // eslint-disable-line
import './_ajax'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
// import 'slick-carousel';
// import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function is_on_screen(elem) {
  let $elem = $(elem);
  let $window = $(window);

  let docViewTop = $window.scrollTop();
  let docViewBottom = docViewTop + $window.height();

  let elemTop = $elem.offset().top;
  let elemBottom = elemTop + $elem.height();

  return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

/**
 * Add data-link-hover attribute to the card
 * with the <a class="link-qrrow"> inside
 * and link will be annimated like on hover effeect
 */
function cardLinkHover(card) {
  card.on('mouseenter', function () {
    $(this).find('.link-arrow').addClass('is-active');
  });
  card.on('mouseleave', function () {
    $(this).find('.link-arrow').removeClass('is-active');
  });
}

const animateSvg = function (el) {
  let section = $(el);
  section.each(function (idx, el) {
    if ($(el).data('animate') === true) {
      let svg = $(el).find('svg');
      if (svg.hasClass('animate-this')) {
        svg.parent('div').addClass('is-active');
      }
    } else {
      let svg = $(el).find('svg');
      if (svg.hasClass('animate-this')) {
        svg.parent('div').removeClass('is-active');
      }
    }
  });
};

const accordion = function (el) {
  let accordionTab = el.find('.tab');
  accordionTab.on('click', function () {
    // el.find('.tab').removeClass('is-active');
    $(this).toggleClass('is-active');
    $(this).siblings('.content').slideToggle();
  });
};

/**
 * Scripts which runs after DOM load
 */
let headerHeight = $('.header').innerHeight(),
  menuContainer = $('.header').find('.menu-container'),
  mainContent = $('.main-content'),
  animatedSection = $('section[data-animate]');
$(document).on('ready', function () {
  if ($('.review-page').length) {
    let form = $('.review-page-popup-form'),
      social = $('.review-page-popup-socials'),
      main = $('.review-page'),
      stars = main.find('.stars-wrapper'),
      close = $('.close-button');
    close.on('click', function () {
      main.removeClass('is-hiden');
      $(this).closest('section').hide();
    });
    stars.on('click', function () {
      stars.removeClass('is-active');
      $(this).addClass('is-active').prevAll().addClass('is-active');
      if ($(this).data('number') > 3) {
        social.show();
        main.addClass('is-hiden');
      } else {
        form.show();
        main.addClass('is-hiden');
      }
    });
    stars.mouseover(function () {
      $(this).addClass('is-active').prevAll().addClass('is-active');
      $(this).nextAll().removeClass('is-active');
    });
  }
  if ($('.employment-form').length) {
    $(document).on('gform_post_render', function () {
      let formId = $('.employment-form').attr('id').slice(-1);
      let $fields = $(`#gform_fields_${formId}`).find('.gfield'),
        fieldSets = $(`#gform_fields_${formId}`).find('fieldset'),
        count,
        sectionIdx = [];
      // inputPhone = $('.gfield_repeater_wrapper .ginput_container_phone').find('input');
      $($fields).each(function (id, el) {
        if ($(el).hasClass('gsection')) {
          sectionIdx.push(id);
        }
      });

      for (let a = 0; a < sectionIdx.length; a++) {
        if (a === 0) {
          for (let i = a; i < sectionIdx[a]; i += sectionIdx[a]) {
            $fields
              .slice(i, i + sectionIdx[a])
              .wrapAll(
                `<div class="form-block-wrapper form-block-wrapper--${a}"></div>`
              );
          }
          count = $fields.length - ($fields.length - sectionIdx[a]) + 1;
        } else {
          for (let s = count; s < sectionIdx[a]; s += sectionIdx[a]) {
            $fields
              .slice(s, sectionIdx[a])
              .wrapAll(
                `<div class="form-block-wrapper form-block-wrapper--${a}"></div>`
              );
          }
          count = $fields.length - ($fields.length - sectionIdx[a]) + 1;
        }
      }

      $(fieldSets).each(function (idx, el) {
        let content = $(el).html();
        // $(el).html('').append(`<div class="test">` + content + `</div>`);
        if ($(el).hasClass('gfield_repeater')) {
          return;
        } else {
          $(el)
            .html('')
            .append(
              `<div class="fieldset-inner fieldset-inner--${idx}">${content}</div>`
            );
        }
      });

      let firstRadioBtns = $(document).find(
          '.onchange-radio-first .gfield-choice-input'
        ),
        firstValue1 = $('.onchange-radio-first__value1').find('input')[0].value,
        firstValue2 = $('.onchange-radio-first__value2').find('input')[0].value,
        dynamicField1 = $('.dynamic-field1').find('input'),
        secondRadioBtns = $(document).find(
          '.onchange-radio-second .gfield-choice-input'
        ),
        secondValue1 = $('.onchange-radio-second__value1').find('input')[0]
          .value,
        secondValue2 = $('.onchange-radio-second__value2').find('input')[0]
          .value,
        dynamicField2 = $('.dynamic-field2').find('input');
      firstRadioBtns.change(function () {
        if (this.value === 'Two-bedroom') {
          dynamicField1[0].value = firstValue1;
        }

        if (this.value === 'Three-bedroom') {
          dynamicField1[0].value = firstValue2;
        }
      });
      secondRadioBtns.change(function () {
        if (this.value === 'Two-bedroom') {
          dynamicField2[0].value = secondValue1;
        }

        if (this.value === 'Three-bedroom') {
          dynamicField2[0].value = secondValue2;
        }
      });

      let $this = $('.employment-form'),
        mainWrapper = $this.find('#gform_fields_1');
      mainWrapper.find('.gfield--type-submit').addClass('tenant-footer');
      mainWrapper
        .find('.tenant-footer')
        .wrapAll('<div class="tenant-footer-wrapper"></div>');
      $(mainWrapper.children('.gfield--type-section')).map(function (idx) {
        if (idx === 7) {
          $(this).hide();
        }
      });
    });
  }
  if ($('.accordion-item').length) {
    accordion($('.accordion-item'));
  }
  if ($('.blog-page__posts__categories').length) {
    $('.blog-page__posts__categories')
      .find('.mobile-tab')
      .on('click', function () {
        $(this).addClass('is-active');
        $(this).siblings('.cat-wrapper').toggle('100');
      });
  }
  if ($('div[data-link-hover]').length) {
    cardLinkHover($('div[data-link-hover]'));
  }
  if ($('.submenu-toggle').length) {
    $('.submenu-toggle').on('click', function () {
      $(this).parent('li').toggleClass('submenu-active');
    });
  }
  // animateSvg($('section'));
  /**
   * Make elements equal height
   */
  // $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $('.menu-icon').on('click', function () {
    $(this).toggleClass('is-active');
    $('.menu-container').toggleClass('is-active');
    $('.top-bar').toggleClass('is-active').slideToggle().css('display', 'flex');
    // $('body').toggleClass('mobile-menu-active');
  });
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.top-bar')
        .toggleClass('is-active')
        .slideToggle()
        .css('display', 'flex');
      // $('.menu-header-menu-container').slideToggle();
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  // if (window.innerWidth > 1024) {
  //   $('.menu-item').each(function (idx, el) {
  //     $(el).attr('role', 'treeitem');
  //   });
  // }

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  // if (window.innerWidth > 1024) {
  //   $('.menu-item').each(function (idx, el) {
  //     $(el).attr('role', 'treeitem');
  //   });
  // }

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */

$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(animatedSection).length) {
    $(animatedSection).each(function (idx, el) {
      if (is_on_screen($(el))) {
        $(el).attr('data-animate', true);
        animateSvg($('section'));
      } else {
        $(el).attr('data-animate', false);
      }
    });
  }
  if ($(window).scrollTop() > headerHeight) {
    $('.header').addClass('sticky-header');
    mainContent.css('padding-top', menuContainer.innerHeight() - 1 + 'px');
    menuContainer.css('top', -menuContainer.innerHeight() - 2 + 'px');
  } else {
    $('.header').removeClass('sticky-header');
    mainContent.css('padding-top', 0);
    menuContainer.css('top', 0);
  }
});
